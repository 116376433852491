import { IContract } from '@/entity/contract/contract';
import { ICreateContract } from '@/entity/contract/create-contract';
import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';
import ApiBase from '@api/api-base';
import { IContractLine } from '@/entity/contract/contract-line';
import { IObjectShort } from '@/entity/shared/object-short';

class ContractApi extends ApiBase<IContract> {
    /**
     *
     */
    constructor(urlBase: string = 'api/contracts') {
        super(urlBase);
    }

    public async createContract(data: ICreateContract): Promise<ICancellableResult<string>> {
        return await ajaxCall.post<string, ICreateContract>(this.url, data);
    }


    public async getAll(): Promise<ICancellableResult<IContract[] | null>> {
        return await ajaxCall.baseAjaxCall<IContract[]>('get', this.url);
    }

    public async getAllByProjectId(projectId: string): Promise<ICancellableResult<IContract[] | null>> {
        return await ajaxCall.baseAjaxCall<IContract[]>('get', this.url, { urlParameter: ['byproject', projectId] });
    }

    public async getContractLinesByProjectId(projectId: number): Promise<ICancellableResult<IContractLine[] | null>>{
        return await ajaxCall.baseAjaxCall<IContractLine[]>('get', this.url, { urlParameter: ['contract-line', projectId.toString()] });
    }

    public async getAllContractLinesByProjectId(
        projectId: string
    ): Promise<ICancellableResult<IContractLine[] | null>> {
        return await ajaxCall.baseAjaxCall<IContractLine[]>('get', this.url, {
            urlParameter: ['getcontractlinelist', projectId]
        });
    }

    public async getContractLineIdList(data: number[]): Promise<ICancellableResult<IObjectShort[]>> {
        return await ajaxCall.post<IObjectShort[], number[]>(this.url + '/contractlineidlist', data);
    }

    public async exportContractData(data: number[], sortKeySelected: string, isSortDesc: boolean): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, any>('post', this.url, {
            payload: {
                ContractIdList: data,
                SortKey: sortKeySelected,
                isSortKeyDesc: isSortDesc,
            },
            urlParameter: ['exportContractData'],
            responseType: 'blob'
        });
    }

    public async exportContractLinesData(data: number): Promise<ICancellableResult<string>> {
        console.log("data");
        console.log(data);
        return await ajaxCall.baseAjaxCall<string, any>('post', this.url, {
            payload: {
                Id: data,
            },
            urlParameter: ['exportContractLinesData'],
            responseType: 'blob'
        });
    }
    
    public async exportInvoicesData(data: number[], sortKeySelected: string, isSortDesc: boolean): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, any>('post', this.url, {
            payload: {
                InvoiceIdList: data,
                SortKey: sortKeySelected,
                isSortKeyDesc: isSortDesc,
            },
            urlParameter: ['exportInvoicesData'],
            responseType: 'blob'
        });
    }

    public async exportContractsData(data: number[], dataAddsColumns: string[], sortKeySelected: string, isSortDesc: boolean, isAdmin: boolean): Promise < ICancellableResult < string >> {
        return await ajaxCall.baseAjaxCall<string, any>('post', this.url, {
            payload: {
                ContractIdList: data,
                AdditionalColumnsList: dataAddsColumns,
                SortKey: sortKeySelected,
                isSortKeyDesc: isSortDesc,
                isAdmin: isAdmin,
            },
            urlParameter: ['exportContractsList'],
            responseType: 'blob'
        });
    }
    
}



export const contractApi: ContractApi = new ContractApi();
