import { IAgency } from '@/entity/project/agency';
import { IStudio } from '@/entity/project/studio';
import {
    IGetByKeyReturnType,
    IListPersistedState,
    IListState,
    IListsStatesKeys
} from '@/entity/shared/ListsStoreState';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { IClient } from '@/entity/shared/client';
import localStorageWrapper from '@t/localStorage';
import { createModule, mutation } from 'vuex-class-component';
import { format } from 'date-fns';

const VuexModule = createModule({
    namespaced: 'employee',
    strict: true
});

export class ListsStore extends VuexModule {
    private _projectsListKey: IListsStatesKeys = 'projects-list-state';
    private _projectsList: IListState = {
        addedAdditionalFields: [],
        additionalFields: [
            { label: 'Affaire Interne', key: 'isInternalBusiness', thStyle: 'width: 125px; padding-left: 5px', sortable: true },
            {
                label: 'Date de Début',
                key: 'openingDate',
                thStyle: 'width: 125px; padding-left: 5px',
                sortable: true,
                formatter: (val: Date, key?: string, item?) => (val ? new Date(val).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: '2-digit' }) : '-'),            
            },
            {
                label: 'Date de fin',
                key: 'closingDate',
                thStyle: 'width: 105px; padding-left: 5px',
                sortable: true,
                formatter: (val: Date, key?: string, item?) => (val ? new Date(val).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: '2-digit' }) : '-'),            
            },
            { label: 'Mandataire', key: 'isDelegate', thStyle: 'width: 105px; padding-left: 5px', sortable: true },
            { label: 'Soldé', key: 'isSale', thStyle: 'width: 80px; padding-left: 5px', sortable: true },
            {
                label: 'Studio',
                key: 'studio',
                thStyle: 'width: 80px; padding-left: 5px',
                sortable: true,
                formatter: (val: IStudio, key?: string, item?) => (val ? (val.label ? val.label : ' ') : ' ')
            }
        ],
        baseFields: [
            { label: 'Trigramme', key: 'trigram', sortable: true, thStyle: 'width:80px; padding-left: 5px', tdClass: 'padding-left-td' },
            { label: 'Désignation', key: 'designation', sortable: true, thStyle: 'padding-left: 5px' },
            {
                label: 'Client',
                key: 'clients',
                thStyle: 'padding-left: 5px',
                sortable: true,
                formatter: (val: IClient[], key?: string, item?) => val.map((x) => x.label).join(', ')
            },
            {
                label: 'Société(s)',
                key: 'agencies',
                thStyle: 'padding-left: 5px',
                sortable: true,
                formatter: (val: IAgency[], key?: string, item?) => val.map((x) => x.label).join(', ')
            },
            {
                label: 'Responsable Studio',
                key: 'studioManagerId',
                thStyle: 'padding-left: 5px',
                sortable: true,
                formatter: (v, _field, _object) => {
                    return '';
                    // const element: MgtPerson = this.$refs['studioManager' + v] as MgtPerson;
                    // if (element && element.id) {
                    //     if (element.personDetails?.displayName) {
                    //         return element.personDetails?.displayName?.toLowerCase();
                    //     }
                    // }
                    // return '';
                },
                filterByFormatted: true
            }
        ],
        filters: {
            trigram: {
                columnName: 'trigram',
                value: ''
            },
            designation: {
                columnName: 'designation',
                value: ''
            },
            clients: {
                columnName: 'clients',
                value: ''
            },
            agencies: {
                columnName: 'agencies',
                value: ''
            },
            studioManagerId: {
                columnName: 'studioManagerId',
                value: ''
            },
            openingDate: {
                columnName: 'openingDate',
                value: '',
            },
            closingDate: {
                columnName: 'closingDate',
                value: '',
            },
            isDelegate: {
                columnName: 'isDelegate',
                value: '',
            },
            isSale: {
                columnName: 'isSale',
                value: '',
            },
            isInternalBusiness: {
                columnName: 'isInternalBusiness',
                value: '',
            },
            studio: {
                columnName: 'studio',
                value: '',
            }
        }
    };

    private _contractsListKey: IListsStatesKeys = 'contracts-list-state';
    private _contractList: IListState = {
        addedAdditionalFields: [],
        additionalFields: [
            {
                label: 'Désignation',
                key: 'designation',
                sortable: true,
                thStyle: 'padding-left: 5px'
            },
            {
                label: 'Société',
                key: 'agencyLabel',
                sortable: true,
                thStyle: 'padding-left: 5px'
            },
            {
                label: 'Échéance règlement',
                key: 'terme',
                sortable: true,
                thStyle: 'padding-left: 5px'
            },
            {
                label: 'Banque',
                key: 'bankLabel',
                sortable: true,
                thStyle: 'padding-left: 5px'
            },
            {
                label: 'Commentaire',
                key: 'comment',
                sortable: true,
                thStyle: 'padding-left: 5px'
            },
            {
                label: 'Compte de vente',
                key: 'salesAccount',
                sortable: true,
                thStyle: 'padding-left: 5px'
            },
            {
                label: "Calcul de la part d'intérêt",
                key: 'interestShareCalculation',
                sortable: true,
                thStyle: 'padding-left: 5px'
            },
            {
                label: 'Taux de mission',
                key: 'insurancePercentage',
                sortable: true,
                thStyle: 'padding-left: 5px'
            },
            {
                label: "Part d'intérêt",
                key: 'interestShare',
                sortable: true,
                thStyle: 'padding-left: 5px'
            }
        ],
        filters: {
            reference: {
                columnName: 'reference',
                value: ''
            },
            description: {
                columnName: 'description',
                value: ''
            },
            date: {
                columnName: 'date',
                value: ''
            },
            clientLabel: {
                columnName: 'clientLabel',
                value: ''
            },
            designation: {
                columnName: 'designation',
                value: ''
            },
            agencyLabel: {
                columnName: 'agencyLabel',
                value: ''
            },
            terme: {
                columnName: 'terme',
                value: ''
            },
            bankLabel: {
                columnName: 'bankLabel',
                value: ''
            },
            comment: {
                columnName: 'comment',
                value: ''
            },
            salesAccount: {
                columnName: 'salesAccount',
                value: ''
            },
            interestShareCalculation: {
                columnName: 'interestShareCalculation',
                value: ''
            },
            buildingWorkAmount: {
                columnName: 'buildingWorkAmount',
                value: ''
            },
            tax: {
                columnName: 'tax',
                value: ''
            },
            trigram: {
                columnName: 'trigram',
                value: ''
            },
            studio: {
                columnName: 'studio',
                value: ''
            },
            projectManagerIds: {
                columnName: 'projectManagerIds',
                value: ''
            },
            insurancePercentage: {
                columnName: 'insurancePercentage',
                value: ''
            },
            interestShare: {
                columnName: 'interestShare',
                value: ''
            },
            totalExcTax: {
                columnName: 'totalExcTax',
                value: ''
            }
        },
        baseFields: [
            { label: 'Trigramme', key: 'trigram', sortable: true, thStyle: 'width:80px; padding-left: 5px' },
            { label: 'Référence', key: 'reference', sortable: true, thStyle: 'padding-left: 5px' },
            { label: 'Description', key: 'description', sortable: true, thStyle: 'padding-left: 5px' },
            {
                label: 'Date de contrat',
                key: 'date',
                thStyle: 'width:115px; padding-left: 5px',
                sortable: true,
                formatter: (val: Date, key?: string, item?) => format(new Date(String(val)), 'dd/MM/yy')
            },
            { label: 'Nom du client', key: 'clientLabel', sortable: true, thStyle: 'padding-left: 5px' },
            { label: 'Studio du projet', key: 'studio', sortable: true, thStyle: 'padding-left: 5px' },
            { label: 'Chef de projet', key: 'projectManagerIds', sortable: true, thStyle: 'padding-left: 5px' },
            { label: 'Montant des travaux', key: 'buildingWorkAmount', thStyle: 'padding-left: 5px', sortable: true, formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)) },
            { label: 'Montant HT contrat (cumul)', key: 'totalExcTax', thStyle: 'padding-left: 5px', sortable: true, formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)) },
            { label: 'Montant HT Facturation (cumul)', key: 'tax', sortable: true, thStyle: 'padding-left: 5px', formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)) }
        ]
    };

    private _purchasesListKey: IListsStatesKeys = 'purchases-list-state';
    private _purchasesList: IListState = {
        baseFields: [
            {
                label: 'Studio',
                key: 'studio',
                sortable: true,
                thStyle: 'padding-left: 5px'
            },
            { label: 'Trigramme', key: 'trigram', sortable: true, thStyle: 'width:80px; padding-left: 5px' },
            { label: 'Nom du fournisseur', key: 'thirdPartyName', sortable: true, thStyle: 'padding-left: 5px' },
            {
                label: 'Référence bon de commande',
                key: 'purchaseOrderReference',
                sortable: true,
                thStyle: 'padding-left: 5px'
            },
            {
                label: 'Date de commande',
                key: 'date',
                sortable: true,
                thStyle: 'padding-left: 5px',
                formatter: (val: Date, key?: string, item?) => (val ? new Date(val).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: '2-digit' }) : '-'),            
            },
            {
                label: 'Montant HT',
                key: 'amountExcTax',
                sortable: true,
                thStyle: 'padding-left: 5px',
                formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val))
            },
            {
                label: 'Montant TTC',
                key: 'amountIncTax',
                sortable: true,
                thStyle: 'padding-left: 5px',
                formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val))
            }
        ],
        addedAdditionalFields: [],
        additionalFields: [
            {
                label: 'Société',
                key: 'company',
                sortable: true,
                thStyle: 'padding-left: 5px'
            },
            {
                label: "Nom de l'affaire",
                key: 'name',
                sortable: true,
                thStyle: 'padding-left: 5px'
            }
        ],
        filters: {
            trigram: {
                columnName: 'trigram',
                value: ''
            },
            studio: {
                columnName: 'studio',
                value: ''
            },
            thirdPartyName: {
                columnName: 'thirdPartyName',
                value: ''
            },
            purchaseOrderReference: {
                columnName: 'purchaseOrderReference',
                value: ''
            },
            date: {
                columnName: 'date',
                value: ''
            },
            amountExcTax: {
                columnName: 'amountExcTax',
                value: ''
            },
            amountIncTax: {
                columnName: 'amountIncTax',
                value: ''
            },
            company: {
                columnName: 'company',
                value: ''
            },
            name: {
                columnName: 'name',
                value: ''
            }
        }
    };

    private _invoicesListKey: IListsStatesKeys = 'invoices-list-state';
    private _invoicesList: IListState = {
        addedAdditionalFields: [],
        additionalFields: [
            {
                label: 'Client',
                key: 'client',
                sortable: true,
                thStyle: 'padding-left: 5px'
            },
            {
                label: 'Société',
                key: 'company',
                sortable: true,
                thStyle: 'padding-left: 5px'
            },
            {
                label: 'Banque',
                key: 'bank',
                sortable: true,
                thStyle: 'padding-left: 5px'
            },
            {
                label: "Nom de l'affaire",
                key: 'name',
                sortable: true,
                thStyle: 'padding-left: 5px'
            }
        ],
        baseFields: [
            {
                label: 'Studio',
                key: 'studio',
                sortable: true,
                thStyle: 'padding-left: 5px'
            },
            { label: 'Trigramme', key: 'trigram', sortable: true, thStyle: 'width: 80px; padding-left: 5px' },
            { label: 'Référence Contrat', key: 'contractReference', sortable: true, thStyle: 'width: 130px; padding-left: 5px' },
            {
                label: 'Référence Facture',
                key: 'invoiceReference',
                sortable: true,
                thStyle: 'padding-left: 5px'
            },
            {
                label: 'Date de facture',
                key: 'date',
                sortable: true,
                thStyle: 'width: 115px; padding-left: 5px',
                formatter: (val: Date, key?: string, item?) => new Date(val).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: '2-digit' } )
            },
            {
                label: 'Montant HT',
                key: 'amountExcTax',
                sortable: true,
                thStyle: 'width: 130px; padding-left: 5px',
                formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val))
            },
            {
                label: 'Montant TTC',
                key: 'amountIncTax',
                sortable: true,
                thStyle: 'width: 130px; padding-left: 5px',
                formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val))
            },
            {
                label: "Date d'échéance",
                key: 'dueDate',
                sortable: true,
                thStyle: 'width: 115px; padding-left: 5px',
                formatter: (val: Date, key?: string, item?) => new Date(val).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: '2-digit' })
            },
            {
                label: 'Date de paiement',
                key: 'paymentDate',
                thStyle: 'width: 115px; padding-left: 5px',
                sortable: true,
                formatter: (val: Date, key?: string, item?) =>
                    val != null ? new Date(val).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: '2-digit' }) : ''
            }
        ],
        filters: {
            trigram: {
                columnName: 'trigram',
                value: ''
            },
            studio: {
                columnName: 'studio',
                value: ''
            },
            contractReference: {
                columnName: 'contractReference',
                value: ''
            },
            invoiceReference: {
                columnName: 'invoiceReference',
                value: ''
            },
            date: {
                columnName: 'date',
                value: ''
            },
            amountExcTax: {
                columnName: 'amountExcTax',
                value: ''
            },
            amountIncTax: {
                columnName: 'amountIncTax',
                value: ''
            },
            dueDate: {
                columnName: 'dueDate',
                value: ''
            },
            paymentDate: {
                columnName: 'paymentDate',
                value: ''
            },
            isInternalBusiness: {
                columnName: 'isInternalBusiness',
                value: ''
            },
            client: {
                columnName: 'client',
                value: ''
            },
            company: {
                columnName: 'company',
                value: ''
            },
            bank: {
                columnName: 'bank',
                value: ''
            },
            name: {
                columnName: 'name',
                value: ''
            }
        }
    };

    private _providerInvoiceListKey: IListsStatesKeys = 'provider_invoices-list-state';
    private _providerInvoiceList: IListState = {
        addedAdditionalFields: [],
        baseFields: [
            { label: 'Studio', key: 'studio', sortable: true, thStyle: 'padding-left: 5px' },
            { label: 'Trigramme', key: 'trigram', sortable: true, thStyle: 'width: 85px; padding-left: 5px' },
            { label: 'Fournisseur', key: 'supplier', sortable: true, thStyle: 'padding-left: 5px' },
            { label: 'Référence Facture', key: 'reference', sortable: true, thStyle: 'padding-left: 5px' },
            { label: 'Référence bon de commande', key: 'purchaseOrderReference', sortable: true, thStyle: 'padding-left: 5px' },
            {
                label: 'Date de facture',
                key: 'date',
                thStyle: 'width: 115px; padding-left: 5px',
                sortable: true,
                formatter: (val: Date, key?: string, item?) => {
                    return val ? format(new Date(String(val)), 'dd/MM/yy') : '';
                }
            },
            { 
                label: 'Montant HT',
                key: 'totalExcTax',
                thStyle: 'width: 130px; padding-left: 5px',
                sortable: true,
                formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)) 
            },
            {
                label: 'Montant TTC',
                key: 'total',
                sortable: true,
                thStyle: 'width: 130px; padding-left: 5px',
                formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val))
            },
            {
                label: "Date d'échéance",
                key: 'deadline',
                thStyle: 'width: 115px; padding-left: 5px',
                sortable: true,
                formatter: (val: Date, key?: string, item?) => {
                    return val ? format(new Date(String(val)), 'dd/MM/yy') : '';
                }
            },
            {
                label: 'Date de réception',
                key: 'receptionDate',
                sortable: true,
                thStyle: 'width: 115px; padding-left: 5px',
                formatter: (val: Date, key?: string, item?) => {
                    return val ? format(new Date(String(val)), 'dd/MM/yy') : '';
                }
            }
        ],
        additionalFields: [
            {
                label: "Nom de l'affaire",
                key: 'matter',
                sortable: true
            },
            {
                label: 'Société',
                key: 'agency',
                sortable: true
            }
        ],
        filters: {
            trigram: {
                columnName: 'trigram',
                value: ''
            },
            studio: {
                columnName: 'studio',
                value: ''
            },
            supplier: {
                columnName: 'supplier',
                value: ''
            },
            reference: {
                columnName: 'reference',
                value: ''
            },
            purchaseOrderReference: {
                columnName: 'purchaseOrderReference',
                value: ''
            },
            date: {
                columnName: 'date',
                value: ''
            },
            totalExcTax: {
                columnName: 'totalExcTax',
                value: ''
            },
            total: {
                columnName: 'total',
                value: ''
            },
            deadline: {
                columnName: 'deadline',
                value: ''
            },
            receptionDate: {
                columnName: 'receptionDate',
                value: ''
            },
            matter: {
                columnName: 'matter',
                value: ''
            },
            agency: {
                columnName: 'agency',
                value: ''
            }
        }
    };

    private _usersListKey: IListsStatesKeys = 'users-list-state';
    private _usersList: IListState = {
        addedAdditionalFields: [],
        additionalFields: [
            {
                label: "Date d'arrivée",
                key: 'entryDate',
                sortable: true,
                thStyle: 'width: 125px; padding-left: 5px',
                formatter: (val: Date, key?: string, item?: any) => {
                    if (!val || val.toString() === 'Invalid Date') {
                        return ''; // return empty string for null or invalid date values
                    }
                    return new Date(val).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: '2-digit' });
                },
                thClass: 'redLength'
            },
            {
                label: 'Date de départ',
                key: 'departureDate',
                sortable: true,
                thStyle: 'width: 125px; padding-left: 5px',
                formatter: (val: Date, key?: string, item?: any) => {
                    if (!val || val.toString() === 'Invalid Date') {
                        return ''; // return empty string for null or invalid date values
                    }
                    return new Date(val).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: '2-digit' });
                },
                thClass: 'redLength'
            },
            {
                label: "Fin de période d'essai",
                key: 'endTrialDate',
                sortable: true,
                thStyle: 'width: 115px; padding-left: 5px',
                formatter: (val: Date, key?: string, item?: any) => {
                    if (!val || val.toString() === 'Invalid Date') {
                        return ''; // return empty string for null or invalid date values
                    }
                    return new Date(val).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: '2-digit' });
                },
                thClass: 'redLength'
            },
            { label: 'Poste', key: 'employeeRole', sortable: true, thStyle: 'width: 115px; padding-left: 5px', },
            { label: 'Service', key: 'employeeService', sortable: true, thClass: 'redLength', thStyle: 'width: 105px; padding-left: 5px', },
            { label: 'Studio', key: 'studio', sortable: true, thClass: 'redLength', thStyle: 'width: 80px; padding-left: 5px', },
            { label: 'Contrat', key: 'employeeContract', sortable: true, thClass: 'contratResize', thStyle: 'width: 90px; padding-left: 5px', },
            { label: 'Matricule', key: 'registrationNumber', sortable: true, thClass: 'redLength', thStyle: 'width: 115px; padding-left: 5px', },
            { label: 'Coefficient', key: 'coefficient', sortable: true, thClass: 'redLength', thStyle: 'width: 115px; padding-left: 5px', }
        ],
        baseFields: [
            {
                key: 'userId',
                label: 'Employé',
                sortable: true,
                thStyle: 'padding-left: 5px',
                formatter: (val: string, key?: string, item?) => val
            },
            {
                key: 'trigram',
                label: 'Trigramme',
                sortable: true,
                thStyle: 'width: 90px; padding-left: 5px',
                formatter: (val: string, key?: string, item?) => val || '-',
                thClass: 'redLength'
            },
            {
                key: 'agency',
                label: 'Société',
                sortable: true,
                thStyle: 'padding-left: 5px',
                formatter: (val?: IAgency, key?: string, item?) => (val ? (val.label ? val.label : '-') : '-')
            },
            {
                key: 'teleworking',
                label: 'Compteur télétravail',
                sortable: true,
                thStyle: 'padding-left: 5px',
                formatter: (val: string, key?: string, item?) => (val ?? 0) + ' jour(s)',
                thClass: 'redLength'
            }
        ],
        filters: {
            userId: {
                columnName: 'userId',
                value: ''
            },
            trigram: {
                columnName: 'trigram',
                value: ''
            },
            agency: {
                columnName: 'agency',
                value: ''
            },
            teleworking: {
                columnName: 'teleworking',
                value: ''
            },
            entryDate: {
                columnName: 'entryDate',
                value: ''
            },
            departureDate: {
                columnName: 'departureDate',
                value: ''
            },
            endTrialDate: {
                columnName: 'endTrialDate',
                value: ''
            },
            poste: {
                columnName: 'poste',
                value: ''
            },
            employeeService: {
                columnName: 'service',
                value: ''
            },
            employeeContract: {
                columnName: 'employeeContract',
                value: ''
            },
            studio: {
                columnName: 'studio',
                value: ''
            },
            employeeRole: {
                columnName: 'employeeRole',
                value: ''
            },
            registrationNumber: {
                columnName: 'registrationNumber',
                value: ''
            },
            coefficient: {
                columnName: 'Coefficient',
                value: ''
            }
        }
    };

    private _getListByKey = (key: IListsStatesKeys): IGetByKeyReturnType => {
        switch (key) {
            case 'contracts-list-state':
                return { state: this._contractList, key: this._contractsListKey };
            case 'projects-list-state':
                return { state: this._projectsList, key: this._projectsListKey };
            case 'purchases-list-state':
                return { state: this._purchasesList, key: this._purchasesListKey };
            case 'invoices-list-state':
                return { state: this._invoicesList, key: this._invoicesListKey };
            case 'provider_invoices-list-state':
                return { state: this._providerInvoiceList, key: this._providerInvoiceListKey };
            case 'users-list-state':
                return { state: this._usersList, key: this._usersListKey };
        }
    };

    private persistState = (listKey: IListsStatesKeys) => {
        const { state, key } = this._getListByKey(listKey);
        localStorageWrapper.setItem<IListPersistedState>(key, {
            addedAdditionalFields: state.addedAdditionalFields,
            filters: state.filters
        });
    };

    get listFields() {
        return (listKey: IListsStatesKeys) => {
            const { state } = this._getListByKey(listKey);
            if (state.addedAdditionalFields) {
                return state.baseFields.concat(state.addedAdditionalFields);
            }
            return state.baseFields;
        };
    }

    get addedAdditionalFields() {
        return (listKey: IListsStatesKeys) => {
            const { state } = this._getListByKey(listKey);
            return state.addedAdditionalFields;
        };
    }

    get additionnalFieldChoice() {
        return (listKey: IListsStatesKeys) => {
            const { state } = this._getListByKey(listKey);
            return state.additionalFields.filter(
                (x) => state.addedAdditionalFields.map((y) => y.key).find((z) => z === x.key) === undefined
            );
        };
    }

    get filters() {
        return (listKey: IListsStatesKeys) => {
            const { state } = this._getListByKey(listKey);
            return state.filters;
        };
    }

    @mutation
    addColumn({ listKey, column }: { listKey: IListsStatesKeys; column: string }) {
        const { state } = this._getListByKey(listKey);
        const fields = state.additionalFields.find((x) => x.label === column);
        if (fields) {
            state.addedAdditionalFields.push(fields);
            this.persistState(listKey);
        }
    }

    @mutation
    removeColumn({ listKey, column }: { listKey: IListsStatesKeys; column: string }) {
        const { state } = this._getListByKey(listKey);
        const fields = state.addedAdditionalFields.findIndex(
            (x: IBootstrapTableColumn | string) => (x as IBootstrapTableColumn).key === column
        );
        if (fields >= 0) {
            state.filters[state.addedAdditionalFields[fields].key!].value = '';
            state.addedAdditionalFields.splice(fields, 1);
            this.persistState(listKey);
        }
    }

    @mutation
    init(listKey: IListsStatesKeys) {
        const { key, state } = this._getListByKey(listKey);
        const listState = localStorageWrapper.getItem<IListState>(key);
        if (listState) {
            state.filters = listState.filters;
            state.addedAdditionalFields = listState.addedAdditionalFields.map((x) => {
                const aditionalFields = state.additionalFields.find((y) => y.key === x.key);
                return {
                    ...x,
                    formatter:
                        aditionalFields && aditionalFields.formatter ? aditionalFields.formatter : (val: any) => val
                };
            });
        }
    }
}
