import { IPurchaseOrder } from '@/entity/purchase-order/purchase-order';
import { ICreatePurchaseOrder } from '@/entity/purchase-order/create-purchase-order';
import { ajaxCall, IBaseAjaxCallParam, ICancellableResult } from '@t/ajax-wrapper';
import ApiBase from '@api/api-base';
import { IPurchaseOrderListLine } from '@/entity/purchase-order/purchase-order-list';

class PurchaseOrderApi extends ApiBase<IPurchaseOrder> {
    /**
     *
     */
    constructor(urlBase: string = 'api/purchaseorders') {
        super(urlBase);
    }

    public async createPurchaseOrder(data: ICreatePurchaseOrder): Promise<ICancellableResult<string>> {
        return await ajaxCall.post<string, ICreatePurchaseOrder>(this.url, data);
    }

    public async getAllByProjectId(projectId: string): Promise<ICancellableResult<IPurchaseOrder[] | null>> {
        return await ajaxCall.baseAjaxCall<IPurchaseOrder[]>('get', this.url, {
            urlParameter: ['byproject', projectId]
        });
    }

    public async exportPurchaseOrderData(
        data: number[],
        sortKeySelected: string,
        isSortDesc: boolean
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, any>('post', this.url, {
            payload: {
                PurchaseOrderIdList: data,
                SortKey: sortKeySelected,
                isSortKeyDesc: isSortDesc
            },
            urlParameter: ['exportPurchaseOrderData'],
            responseType: 'blob'
        });
    }

    public async exportPurchaseOrderGlobalListData(
        data: number[],
        startDate: Date,
        endDate: Date | null,
        extraColumns: Array<string>,
        sortKeySelected: string,
        isSortDesc: boolean
    ) {
        const parameters: IBaseAjaxCallParam<any> = {
            payload: {
                PurchaseOrderIdList: data,
                AdditionalColumnsList: extraColumns,
                SortKey: sortKeySelected,
                isSortKeyDesc: isSortDesc
            },
            urlParameter: ['exportPurchaseOrderGlobalListData'],
            responseType: 'blob',
            queryParameter: {
                startDate: startDate.toISOString()
            }
        };
        if (endDate != null) {
            parameters!.queryParameter!['endDate'] = endDate.toISOString();
        }

        return await ajaxCall.baseAjaxCall<string, any>('post', this.url, parameters);
    }

    public async getGlobalPurchaseOrderList(
        startDate: Date,
        endDate: Date | null
    ): Promise<ICancellableResult<IPurchaseOrderListLine[]>> {
        if (endDate == null) {
            return await ajaxCall.baseAjaxCall<IPurchaseOrderListLine[]>('get', this.url, {
                urlParameter: ['list', 'global'],
                queryParameter: {
                    startDate: startDate.toISOString()
                }
            });
        } else {
            return await ajaxCall.baseAjaxCall<IPurchaseOrderListLine[]>('get', this.url, {
                urlParameter: ['list', 'global'],
                queryParameter: {
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString()
                }
            });
        }
    }
}

export const purchaseOrderApi: PurchaseOrderApi = new PurchaseOrderApi();
