import { createModule, mutation, action } from 'vuex-class-component';
import { authModule } from '@t/session';
import { AccountInfo } from '@azure/msal-browser';
import { appTokenMgr } from '@t/employee-app-role';
import getAdGroupId from '@t/get-ad-groupId';

const VuexModule = createModule({
    namespaced: 'user',
    strict: true
});

export class EmployeeStore extends VuexModule {
    private isAdminEmployee: boolean = false;
    private isAssociateEmployee: boolean = false;
    private isDafEmployee: boolean = false;
    private isUserEmployee: boolean = false;
    private isCPEmployee: boolean = false;
    private isStudioManagerEmployee: boolean = false;
    private isRHEmployee: boolean = false;

    public acc = authModule.getAccount() as AccountInfo;
    private _adGroupId: null | string = null;

    get account (): AccountInfo {
        return this.acc;
    }

    get isAdmin(): boolean {
        return this.isAdminEmployee;
    }
    get isAssociate(): boolean {
        return this.isAssociateEmployee;
    }
    get isDaf(): boolean {
        return this.isDafEmployee;
    }
    get isUser(): boolean {
        return this.isUserEmployee;
    }
    get isCP(): boolean {
        return this.isCPEmployee;
    }
    get isStudioManager(): boolean {
        return this.isStudioManagerEmployee;
    }
    get isRH(): boolean {
        return this.isRHEmployee;
    }

    get adGroupId(): null | string {
        return this._adGroupId;
    }
    
    @action
    public async checkIsAdmin(): Promise<void> {       
        this.setIsAdmin(appTokenMgr.isAdmin(this.acc));
    }

    @action
    public async checkIsAssociate(): Promise<void> {       
        this.setIsAssociate(appTokenMgr.isAssociate(this.acc));
    }
    @action
    public async checkIsDaf(): Promise<void> {       
        this.setIsDaf(appTokenMgr.isDaf(this.acc));
    }
    @action
    public async checkIsUser(): Promise<void> {       
        this.setIsUser(appTokenMgr.isUser(this.acc));
    }
    @action
    public async checkIsCP(): Promise<void> {       
        this.setIsCP(appTokenMgr.isCP(this.acc));
    }
    @action
    public async checkIsStudioManager(): Promise<void> {       
        this.setIsStudioManager(appTokenMgr.isStudioManager(this.acc));
    }
    @action
    public async checkIsRH(): Promise<void> {       
        this.setIsRH(appTokenMgr.isRH(this.acc));
    }

    @action 
    public async getAdGroupId(): Promise<void> {
        if (this._adGroupId) return; // Already fetched
        this.setAdGroupId(await getAdGroupId('Indicateur Richez_Associés'));
    }

    @mutation
    private setIsAdmin(state: boolean) {
        this.isAdminEmployee = state;
    }
    @mutation
    private setIsRH(state: boolean) {
        this.isRHEmployee = state;
    }
    @mutation
    private setIsCP(state: boolean) {
        this.isCPEmployee = state;
    }
    @mutation
    private setIsStudioManager(state: boolean) {
        this.isStudioManagerEmployee = state;
    }
    @mutation
    private setIsDaf(state: boolean) {
        this.isDafEmployee = state;
    }
    @mutation
    private setIsUser(state: boolean) {
        this.isUserEmployee = state;
    }
    @mutation
    private setIsAssociate(state: boolean) {
        this.isAssociateEmployee = state;
    }

    @mutation
    private setAdGroupId(state: null | string) {
        this._adGroupId = state;
    }
}
