import { Client } from '@microsoft/microsoft-graph-client';
import { authModule } from '@t/session';

class ModuleApiGraph {
    private client: Client;
    /**
     *
     */
    constructor() {
        const options = {
            authProvider: authModule // An instance created from previous step
        };
        this.client = Client.initWithMiddleware(options);
    }

    get Client(): Client {
        return this.client;
    }
}
export const moduleApiGraph: ModuleApiGraph = new ModuleApiGraph();
