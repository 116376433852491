import { IFee } from '@/entity/fee/fee';
import ApiBase from '@api/api-base';
import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';

class FeeApi extends ApiBase<IFee> {
    /**
     *
     */
    constructor(urlBase: string = 'api/users') {
        super(urlBase);
    }

    public async getAllByProjectId(projectId: string, userId: string): Promise<ICancellableResult<IFee[] | null>> {
        return await ajaxCall.baseAjaxCall<IFee[]>('get', this.url, { urlParameter: [userId, 'fees', 'byproject', projectId] });
    }

    public async getAllFeesByProjectId(projectId: string): Promise<ICancellableResult<IFee[] | null>> {
        return await ajaxCall.baseAjaxCall<IFee[]>('get', this.url, { urlParameter: ['fees', 'byproject', projectId] });
    }

    public async getAllByUserId(userId: string): Promise<ICancellableResult<IFee[]>> {
        return await ajaxCall.baseAjaxCall<IFee[]>('get', this.url, { urlParameter: [userId, 'fees', userId] });
    }

    public async createFee(data: IFee[], userId: string): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, IFee[]>('post', this.url, { urlParameter: [userId, 'fees'], payload: data });
    }

    public async updateFee(data: IFee[], userId: string): Promise<ICancellableResult<boolean>> {
        return await ajaxCall.baseAjaxCall<boolean, IFee[]>('patch', this.url, { urlParameter: [userId, 'fees'], payload: data });
    }
}

export const feeApi: FeeApi = new FeeApi();
