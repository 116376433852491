import { createModule, mutation, action } from 'vuex-class-component';

const VuexModule = createModule({
    namespaced: 'app',
    strict: true
});
export class AppStore extends VuexModule {
    private extendSidebar: boolean = false;
    private titleMain: string = '';
    private titleExt: string = '';

    get titleValueMain(): string {
        return this.titleMain;
    }

    get titleValueExt(): string {
        return this.titleExt;
    }

    get sidebarCollapsed(): boolean {
        return !this.extendSidebar;
    }

    @action
    public async clearTitle(): Promise<void> {
        this.setTitleMain('');
        this.setTitleExt('');
    }

    @action
    public async changeTitleMain(title: string): Promise<void> {
        this.setTitleMain(title);
    }

    @action
    public async changeTitleExt(title: string): Promise<void> {
        this.setTitleExt(title);
    }

    @action
    public async hideSidebar(): Promise<void> {
        if (this.extendSidebar === true) this.setExtendSidebar(false);
    }

    @action
    public async showSidebar(): Promise<void> {
        if (this.extendSidebar === false) this.setExtendSidebar(true);
    }

    @mutation
    private setTitleMain(state: string) {
        this.titleMain = state;
    }

    @mutation
    private setTitleExt(state: string) {
        this.titleExt = state;
    }

    @mutation
    private setExtendSidebar(state: boolean) {
        this.extendSidebar = state;
    }
}
