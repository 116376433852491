const localStorageWrapper = {
    setItem<T>(key: string, object: T) {
        localStorage.setItem(key, JSON.stringify(object));
    },
    getItem<T>(key: string): T | undefined {
        const item = localStorage.getItem(key);
        if (item) {
            return JSON.parse(item);
        }
    }
};

export default localStorageWrapper;
