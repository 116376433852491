import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';

export default class ApiBase<T> {
    url: string = '';

    constructor(urlBase: string) {
        this.url = urlBase;
    }

    public async getAllBase(): Promise<ICancellableResult<T[]> | null> {
        return await ajaxCall.get<T[]>(this.url);
    }

    public async getBase(id: string): Promise<ICancellableResult<T> | null> {
        return await ajaxCall.baseAjaxCall<T>('get', this.url, { urlParameter: [`/${id}`] });
    }

    public async postBase(data: T): Promise<ICancellableResult<string> | null> {
        return await ajaxCall.post<string, T>(this.url, data);
    }

    public async patchBase(id: string, data: T): Promise<ICancellableResult<boolean>> {
        return await ajaxCall.baseAjaxCall<boolean, T>('patch', this.url, { urlParameter: [id], payload: data });
    }

    public async deleteBase(id: string): Promise<ICancellableResult<boolean>> {
        return await ajaxCall.baseAjaxCall<boolean>('delete', this.url, { urlParameter: [`/${id}`] });
    }
}
