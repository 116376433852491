import { IEmployeeAppRole } from '@/entity/shared/employee';
import { AccountTokenClaims } from '@/entity/shared/user';
import { AccountInfo } from '@azure/msal-browser';
import { authModule } from './session';
import { NU } from './type';

export interface IEmployeeAppRoleManager {
    isAdmin(acc?: NU<AccountInfo>): boolean;
    isAssociate(acc?: NU<AccountInfo>): boolean;
    isDaf(acc?: NU<AccountInfo>): boolean;
    isUser(acc?: NU<AccountInfo>): boolean;
    isCP(acc?: NU<AccountInfo>): boolean;
    isStudioManager(acc?: NU<AccountInfo>): boolean;
    isRH(acc?: NU<AccountInfo>): boolean;
}

class EmployeeAppRoleManager implements IEmployeeAppRoleManager {
    private hasRole(data: { appRole: IEmployeeAppRole; roles: string[] | null }): boolean {
        if (data.roles == null) {
            const acc = authModule.getAccount();
            if (acc != null) {
                data.roles = (acc.idTokenClaims as AccountTokenClaims).roles;
            }
        }
        if (data.roles && data.roles.length > 0) {
            return data.roles.indexOf(data.appRole) >= 0;
        }
        return false;
    }

    public isAdmin(acc: NU<AccountInfo> = null): boolean {
        return this.hasRole({
            appRole: 'Admin',
            roles: (acc?.idTokenClaims as AccountTokenClaims | null)?.roles ?? null
        });
    }

    public isAssociate(acc: NU<AccountInfo> = null): boolean {
        return this.hasRole({
            appRole: 'Associate',
            roles: (acc?.idTokenClaims as AccountTokenClaims | null)?.roles ?? null
        });
    }

    public isDaf(acc: NU<AccountInfo> = null): boolean {
        return this.hasRole({
            appRole: 'DAF',
            roles: (acc?.idTokenClaims as AccountTokenClaims | null)?.roles ?? null
        });
    }

    public isUser(acc: NU<AccountInfo> = null): boolean {
        return this.hasRole({
            appRole: 'User',
            roles: (acc?.idTokenClaims as AccountTokenClaims | null)?.roles ?? null
        });
    }

    public isCP(acc: NU<AccountInfo> = null): boolean {
        return this.hasRole({ appRole: 'CP', roles: (acc?.idTokenClaims as AccountTokenClaims | null)?.roles ?? null });
    }

    public isStudioManager(acc: NU<AccountInfo> = null): boolean {
        return this.hasRole({
            appRole: 'StudioManager',
            roles: (acc?.idTokenClaims as AccountTokenClaims | null)?.roles ?? null
        });
    }

    public isRH(acc: NU<AccountInfo> = null): boolean {
        return this.hasRole({
            appRole: 'RH',
            roles: (acc?.idTokenClaims as AccountTokenClaims | null)?.roles ?? null
        });
    }
}

export const appTokenMgr: IEmployeeAppRoleManager = new EmployeeAppRoleManager();
